<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col lg="6" class="auth-bg px-2 p-lg-5 pt-lg-0 pt-2 pb-5 overflow-auto">
        <b-col sm="12" md="10" lg="10" class="mx-auto mb-2 px-2">
          <h4 class="font-weight-bold mb-0 title">Client Connect</h4>
        </b-col>
        <!-- form -->
        <b-col sm="12" md="12" lg="10" class="mx-auto pb-3">
          <validation-observer ref="simpleRules">
            <b-form class="mt-1">
              <b-row class="px-1">
                <b-col lg="5">
                  <b-form-group label="Country Code" label-for="Country Code">
                    <b-form-select
                      v-model="selectCountryCode"
                      :options="countryCodeOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="7">
                  <b-form-group
                    label="Primary Mobile Number"
                    label-for="Primary Mobile Number"
                    class=""
                  >
                    <b-form-input
                      id="primary_number"
                      placeholder="Enter Mobile Number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-col cols="12">
                <b-form-group label="Email ID" label-for="Email ID">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      placeholder="Enter Email ID"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Name of Your Organisation"
                  label-for="Name of Your Organisation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="organization"
                    rules="required"
                  >
                    <b-form-input
                      id="organization"
                      v-model="organization"
                      placeholder="Enter Organization Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Industry" label-for="industry">
                  <v-select
                    v-model="selectIndustry"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="title"
                    :options="industryOptions"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="A brief about your business">
                  <b-form-textarea
                    id="textarea-rows"
                    placeholder="Write Here"
                    rows="4"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="How VEP can help you ?">
                  <b-form-textarea
                    id="textarea-rows"
                    placeholder="Write Here"
                    rows="4"
                  />
                </b-form-group>
              </b-col>

              <!-- login button -->
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  class="my-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store/index';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { email, required } from '@validations';
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import vSelect from 'vue-select';

export default {
  components: {
    BFormSelect,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      selectCountryCode: '',
      organization: '',
      selectIndustry: [],
      countryCodeOptions: [
        { value: '+61', text: 'Australia(+61)' },
        { value: '+689', text: 'French(+689)' },
      ],
      industryOptions: [{ title: 'Option 1' }, { title: 'Option 2' }],
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert('login successfully');
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.logo_img {
  width: 120px !important;
  margin-left: -80px;
}
</style>
